var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"merch"},[_c('el-form',{ref:"queryParams",attrs:{"model":_vm.queryParams,"inline":true},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleQuery.apply(null, arguments)}}},[_c('el-form-item',[_c('el-select',{attrs:{"clearable":"","placeholder":"状态"},model:{value:(_vm.queryParams.auditSataus),callback:function ($$v) {_vm.$set(_vm.queryParams, "auditSataus", $$v)},expression:"queryParams.auditSataus"}},[_c('el-option',{attrs:{"label":"通过","value":1}}),_c('el-option',{attrs:{"label":"拒绝","value":0}})],1)],1),_c('el-form-item',[_c('el-select',{attrs:{"clearable":"","placeholder":"对码结果"},model:{value:(_vm.queryParams.matchStatus),callback:function ($$v) {_vm.$set(_vm.queryParams, "matchStatus", $$v)},expression:"queryParams.matchStatus"}},[_c('el-option',{attrs:{"label":"对码成功","value":1}}),_c('el-option',{attrs:{"label":"对码失败","value":2}})],1)],1),_c('el-form-item',[_c('el-input',{attrs:{"placeholder":"商品货号/名称","clearable":""},model:{value:(_vm.queryParams.articleNumber),callback:function ($$v) {_vm.$set(_vm.queryParams, "articleNumber", $$v)},expression:"queryParams.articleNumber"}})],1),_c('el-form-item',[_c('el-button',{staticStyle:{"width":"80px"},attrs:{"type":"primary"},on:{"click":_vm.handleQuery}},[_vm._v("查 询")])],1)],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.dataList,"border":"","header-cell-style":{ background: '#F2F3F5' }}},[_c('el-table-column',{attrs:{"prop":"id","label":"编号","align":"center"}}),_c('el-table-column',{attrs:{"label":"商品信息","align":"center","width":"350"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"fx"},[(row.pictureUrl)?_c('div',[_c('el-image',{staticStyle:{"width":"100px","height":"100px","margin-right":"10px"},attrs:{"src":_vm.handleImageError(row.pictureUrl[0]),"preview-src-list":row.pictureUrl}})],1):_vm._e(),_c('div',{staticStyle:{"text-align":"left","font-size":"12px","color":"#777","line-height":"20px"}},[_c('div',{staticStyle:{"font-size":"14px","color":"#000"}},[_vm._v(" "+_vm._s(row.goodsName)+" ")]),_c('div',[_vm._v("规格："+_vm._s(row.specification))]),_c('div',[_vm._v("厂家："+_vm._s(row.manufacturer))]),_c('div',[_vm._v("产地："+_vm._s(row.producingArea))])])])]}}])}),_c('el-table-column',{attrs:{"prop":"approvalNumber","label":"批准文号","align":"center"}}),_c('el-table-column',{attrs:{"prop":"dosageForm","label":"剂型","align":"center"}}),(_vm.fileType == 0 || _vm.fileType == 1)?_c('el-table-column',{attrs:{"prop":"","label":"对码结果","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[(row.matchStatus == 0)?_c('span',{staticStyle:{"color":"#f34444"}},[_vm._v(" 对码失败 ")]):_c('span',[_c('span',{staticStyle:{"color":"#3dbb2b"}},[_vm._v("对码成功")]),_c('span',[_vm._v("("+_vm._s(row.matchStatus == 1 ? "自动" : "手动")+")")])])])]}}],null,false,2863509042)}):_vm._e(),(_vm.fileType == 2)?_c('el-table-column',{attrs:{"prop":"","label":"更改后","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.pictureUrl)?_c('div',[_c('el-image',{staticStyle:{"width":"100px","height":"100px","margin-right":"10px"},attrs:{"src":_vm.handleImageError(row.pictureUrl[0]),"preview-src-list":row.pictureUrl}})],1):_vm._e()]}}],null,false,3613163419)}):_vm._e(),(_vm.fileType == 3)?_c('el-table-column',{attrs:{"prop":"goodsSpecification","label":"说明书","align":"center","width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.goodsSpecification)?_c('div',[_c('div',{staticClass:"fx"},[_c('span',{staticClass:"single-line-ellipsis",domProps:{"innerHTML":_vm._s(row.goodsSpecification)}}),_c('span',[_vm._v("...")])]),_c('span',{staticClass:"btsed",on:{"click":function($event){return _vm.ViewInstructionManual(row)}}},[_vm._v("立即查看")])]):_c('div',[_vm._v("——")])]}}],null,false,4258384736)}):_vm._e(),_c('el-table-column',{attrs:{"label":"资料库信息","align":"center","width":"350"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"fx"},[(row.platformgoodspictureurl)?_c('div',[_c('el-image',{staticStyle:{"width":"100px","height":"100px","margin-right":"10px"},attrs:{"src":_vm.handleImageError(row.platformgoodspictureurl[0]),"preview-src-list":row.platformgoodspictureurl}})],1):_vm._e(),_c('div',{staticStyle:{"text-align":"left","font-size":"12px","color":"#777","line-height":"20px"}},[_c('div',{staticStyle:{"font-size":"14px","color":"#000"}},[_vm._v(" "+_vm._s(row.platformGoodsInfoName)+" ")]),_c('div',[_vm._v("规格："+_vm._s(row.platformGoodsInfoSpecification))]),_c('div',[_vm._v("厂家："+_vm._s(row.platformGoodsInfoManufacturer))]),_c('div',[_vm._v("批准文号："+_vm._s(row.platformGoodsInfoApprovalNumber))]),_c('div',[_vm._v("产地："+_vm._s(row.platformGoodsInfoPlaceOrigin))]),_c('div',[_vm._v("资料库ID："+_vm._s(row.platformGoodsInfoId))])])])]}}])}),_c('el-table-column',{attrs:{"label":"审核结果","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.auditStatus == 0)?_c('span',[_vm._v("未审核")]):_vm._e(),(row.auditStatus == 1)?_c('span',{staticStyle:{"color":"#3dbb2b"}},[_vm._v("通过")]):_vm._e(),(row.auditStatus == 2)?_c('span',{staticStyle:{"color":"#f34444"}},[_vm._v("拒绝")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"refuseReason","label":"拒绝原因","align":"center"}}),(_vm.fileType == 2 || _vm.fileType == 3)?_c('el-table-column',{attrs:{"label":_vm.fileType == 2 ? '是否替换资料库图片' : '是否替换资料库说明书',"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.isUpdate == 1)?_c('span',{staticStyle:{"color":"#3dbb2b"}},[_vm._v("是")]):_c('span',{staticStyle:{"color":"#f34444"}},[_vm._v("否")])]}}],null,false,1396034015)}):_vm._e()],1),_c('pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.total > 0),expression:"total > 0"}],attrs:{"total":_vm.total,"page":_vm.queryParams.page,"limit":_vm.queryParams.perPage},on:{"update:page":function($event){return _vm.$set(_vm.queryParams, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.queryParams, "perPage", $event)},"pagination":_vm.getList}}),_c('el-dialog',{attrs:{"title":"说明书","visible":_vm.dialogInstruction,"close-on-click-modal":false,"width":"800px"},on:{"update:visible":function($event){_vm.dialogInstruction=$event}}},[_c('div',{staticStyle:{"height":"550px","width":"750px","margin-top":"-10px"}},[_c('pre',[_c('Editor',{staticClass:"Editor",model:{value:(_vm.goodsSpecification),callback:function ($$v) {_vm.goodsSpecification=$$v},expression:"goodsSpecification"}})],1)]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogInstruction = false}}},[_vm._v("关 闭")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }