<template>
  <div class="merch">
    <!-- 搜索列 -->
    <el-form
      ref="queryParams"
      :model="queryParams"
      :inline="true"
      @keyup.enter.native="handleQuery"
    >
      <el-form-item>
        <el-select
          clearable
          v-model="queryParams.auditSataus"
          placeholder="状态"
        >
          <el-option label="通过" :value="1"></el-option>
          <el-option label="拒绝" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          clearable
          v-model="queryParams.matchStatus"
          placeholder="对码结果"
        >
          <el-option label="对码成功" :value="1"></el-option>
          <el-option label="对码失败" :value="2"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-input
          v-model="queryParams.articleNumber"
          placeholder="商品货号/名称"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleQuery" style="width: 80px"
          >查 询</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 列表 -->
    <el-table
      :data="dataList"
      v-loading="loading"
      border
      :header-cell-style="{ background: '#F2F3F5' }"
    >
      <el-table-column prop="id" label="编号" align="center" />
      <el-table-column label="商品信息" align="center" width="350">
        <template slot-scope="{ row }">
          <div class="fx">
            <div v-if="row.pictureUrl">
              <el-image
                style="width: 100px; height: 100px; margin-right: 10px"
                :src="handleImageError(row.pictureUrl[0])"
                :preview-src-list="row.pictureUrl"
              >
              </el-image>
            </div>
            <div
              style="
                text-align: left;
                font-size: 12px;
                color: #777;
                line-height: 20px;
              "
            >
              <div style="font-size: 14px; color: #000">
                {{ row.goodsName }}
              </div>
              <div>规格：{{ row.specification }}</div>
              <div>厂家：{{ row.manufacturer }}</div>
              <div>产地：{{ row.producingArea }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="approvalNumber" label="批准文号" align="center" />
      <el-table-column prop="dosageForm" label="剂型" align="center" />
      <el-table-column
        prop=""
        label="对码结果"
        align="center"
        v-if="fileType == 0 || fileType == 1"
      >
        <template slot-scope="{ row }">
          <div>
            <span v-if="row.matchStatus == 0" style="color: #f34444">
              对码失败
            </span>
            <span v-else>
              <span style="color: #3dbb2b">对码成功</span>
              <span>({{ row.matchStatus == 1 ? "自动" : "手动" }})</span>
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        label="更改后"
        align="center"
        v-if="fileType == 2"
      >
        <template slot-scope="{ row }">
          <div v-if="row.pictureUrl">
            <el-image
              style="width: 100px; height: 100px; margin-right: 10px"
              :src="handleImageError(row.pictureUrl[0])"
              :preview-src-list="row.pictureUrl"
            >
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="goodsSpecification"
        label="说明书"
        align="center"
        v-if="fileType == 3"
        width="300"
      >
        <template slot-scope="{ row }">
          <div v-if="row.goodsSpecification">
            <div class="fx">
              <span
                class="single-line-ellipsis"
                v-html="row.goodsSpecification"
              ></span>
              <span>...</span>
            </div>
            <span class="btsed" @click="ViewInstructionManual(row)"
              >立即查看</span
            >
          </div>
          <div v-else>——</div>
        </template>
      </el-table-column>
      <el-table-column label="资料库信息" align="center" width="350">
        <template slot-scope="{ row }">
          <div class="fx">
            <div v-if="row.platformgoodspictureurl">
              <el-image
                style="width: 100px; height: 100px; margin-right: 10px"
                :src="handleImageError(row.platformgoodspictureurl[0])"
                :preview-src-list="row.platformgoodspictureurl"
              >
              </el-image>
            </div>
            <div
              style="
                text-align: left;
                font-size: 12px;
                color: #777;
                line-height: 20px;
              "
            >
              <div style="font-size: 14px; color: #000">
                {{ row.platformGoodsInfoName }}
              </div>
              <div>规格：{{ row.platformGoodsInfoSpecification }}</div>
              <div>厂家：{{ row.platformGoodsInfoManufacturer }}</div>
              <div>批准文号：{{ row.platformGoodsInfoApprovalNumber }}</div>
              <div>产地：{{ row.platformGoodsInfoPlaceOrigin }}</div>
              <div>资料库ID：{{ row.platformGoodsInfoId }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="审核结果" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.auditStatus == 0">未审核</span>
          <span v-if="row.auditStatus == 1" style="color: #3dbb2b">通过</span>
          <span v-if="row.auditStatus == 2" style="color: #f34444">拒绝</span>
        </template>
      </el-table-column>
      <el-table-column prop="refuseReason" label="拒绝原因" align="center" />
      <el-table-column
        v-if="fileType == 2 || fileType == 3"
        :label="fileType == 2 ? '是否替换资料库图片' : '是否替换资料库说明书'"
        align="center"
      >
        <template slot-scope="{ row }">
          <span v-if="row.isUpdate == 1" style="color: #3dbb2b">是</span>
          <span v-else style="color: #f34444">否</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.perPage"
      @pagination="getList"
    />
    <!-- 说明书弹窗 -->
    <el-dialog
      title="说明书"
      :visible.sync="dialogInstruction"
      :close-on-click-modal="false"
      width="800px"
    >
      <div style="height: 550px; width: 750px; margin-top: -10px">
        <pre><Editor v-model="goodsSpecification" class="Editor" /></pre>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogInstruction = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getGoodsImportAuditList, //审核明细列表
} from "@/api/apiAll/phpUrl.js";
import Editor from "@/components/Editor/index.vue"; //商品说明书
export default {
  name: "",
  components: {
    Editor,
  },
  data() {
    return {
      fileType: this.$route.query.fileType,
      loading: false,
      dataList: [],
      total: 0,
      queryParams: {
        id: "",
        page: 1,
        perPage: 10,
        auditSataus: "", //审核状态0：拒绝 1：通过
        matchStatus: "", //对码结果0失败1成功
        articleNumber: "",
      },

      dialogInstruction: false,
      goodsSpecification: "",
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      this.loading = true;
      this.queryParams.id = this.$route.query.id;
      let res = await getGoodsImportAuditList(this.queryParams);
      this.loading = false;
      if (res.code == 200) {
        this.dataList = res.data.data;
        this.total = res.data.total;
      }
    },
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    ViewInstructionManual(row) {
      this.goodsSpecification = row.goodsSpecification;
      this.dialogInstruction = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.single-line-ellipsis {
  width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
